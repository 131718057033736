import { CircularProgress } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

import { AnimatePresence } from "framer-motion";

import { Base, BaseContainer } from "./styles";

interface SavingSuccessProps {
  topOffset?: number;
  progressSize?: number;
  saving?: boolean;
  savingSuccess?: boolean;
}

const variants = {
  hidden: { opacity: 0, x: 0, scale: 0 },
  enter: { opacity: 1, x: 0, scale: 1 },
  exit: { opacity: 0, x: 0, scale: 0 },
};

const SavingSuccess = ({
  topOffset,
  saving,
  savingSuccess,
  progressSize,
}: SavingSuccessProps) => {
  return (
    <Base active={saving || savingSuccess}>
      <AnimatePresence>
        {saving && (
          <BaseContainer
            style={{ top: topOffset }}
            key="saving"
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{
              type: "spring",
              duration: 0.3,
            }}
          >
            <CircularProgress size={progressSize} />
          </BaseContainer>
        )}
        {savingSuccess && (
          <BaseContainer
            style={{ top: topOffset }}
            key="savingSuccess"
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{
              type: "spring",
              duration: 0.3,
            }}
          >
            <DoneIcon />
          </BaseContainer>
        )}
      </AnimatePresence>
    </Base>
  );
};

SavingSuccess.defaultProps = {
  topOffset: 0,
  progressSize: 20,
  saving: false,
  savingSuccess: false,
};

export default SavingSuccess;

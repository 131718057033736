import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from "@microsoft/signalr";
import PushType from "./entities/PushType";
import ManageSessionPush from "./entities/ManageSessionPush";

export type ManageSessionEventType = "AuditionStatusUpdated";

//Event type
export type WebSocketEvent = {
  type: ManageSessionEventType;
  sessionTimeslotId: string | null;
  artistId: string | null;
  timeslotAuditionStatus: string | null;
};

export type SubscriberItem = {
  id: string;
  callback: (event: WebSocketEvent) => void;
};

class WebSocketManageSessionService {
  /********************************************** */
  // vars
  /********************************************** */
  wsClient: HubConnection | null = null;
  url: string | null = "";
  subscribers: SubscriberItem[] = [];

  /********************************************** */
  // Constructor
  /********************************************** */
  constructor(url: string) {
    this.url = url;
  }

  /********************************************************************** */
  // Private
  /********************************************************************** */

  /********************************************** */
  // Transform response
  /********************************************** */
  private _toPushData(data: any): ManageSessionPush | null {
    if (data === null) return null;
    return {
      type: data?.type ?? PushType.AuditionStatusUpdated,
      sessionTimeslotId: data?.sessionTimeslotId ?? null,
      artistId: data?.artistId ?? null,
      timeslotAuditionStatus: data?.timeslotAuditionStatus ?? null,
    };
  }

  /********************************************** */
  // Determine event
  /********************************************** */
  private _toSubmissionEvent(type: PushType): ManageSessionEventType {
    switch (type) {
      case PushType.AuditionStatusUpdated:
        return "AuditionStatusUpdated";
    }
  }

  /********************************************** */
  // Initiate connection
  /********************************************** */

  private _init(url: string): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(url, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
      })
      .withAutomaticReconnect()
      .build();
  }

  /********************************************** */
  // Event - Initial connection
  /********************************************** */
  private _onIndividualConnectionHandler = () => {
    console.log("Connected");
  };

  /********************************************** */
  // Event - Data update
  /********************************************** */
  private _onReceiveDataHandler =
    (callback: (event: WebSocketEvent) => void) => (data: any) => {
      const pushData = this._toPushData(data);
      if (pushData === null) return;
      const pushType = this._toSubmissionEvent(pushData.type);
      // Notify subscribers
      callback({ ...pushData, type: pushType });
      // this._notifySubscribers({ ...pushData, type: pushType });
    };

  /********************************************** */
  // Event - Connection closed
  /********************************************** */
  private _onCloseHandler = (error: any) => {};

  private _toPath(url: string, sessionId: string): string {
    return url.replace("[sessionId]", sessionId);
  }

  /********************************************************************** */
  // Public
  /********************************************************************** */

  /********************************************** */
  // Initiate connection
  /********************************************** */
  public async connect(
    sessionId: string,
    callback: (evt: WebSocketEvent) => void
  ): Promise<void> {
    // Check dependencies
    if (typeof sessionId !== "string") {
      throw new Error("sessionId must be a string");
    }
    if (this.url === "") {
      throw new Error("No url provided");
    }

    // Initialise connection
    const path = this._toPath(this.url!, sessionId);
    this.wsClient = this._init(path);
    this.wsClient.on(
      "onIndividualConnection",
      this._onIndividualConnectionHandler
    );
    this.wsClient.on("receiveData", this._onReceiveDataHandler(callback));
    this.wsClient.onclose(this._onCloseHandler);

    // Initiate connection
    await this.wsClient.start();
  }

  /********************************************** */
  // Disconnect
  /********************************************** */
  public async disconnect(
    callback: (evt: WebSocketEvent) => void
  ): Promise<void> {
    if (this.wsClient === null) return;

    // Garbage collection
    this.wsClient.off(
      "onIndividualConnection",
      this._onIndividualConnectionHandler
    );
    this.wsClient.off("receiveData", this._onReceiveDataHandler(callback));

    // Close connection
    await this.wsClient.stop();
    this.wsClient = null;
  }
}

export default WebSocketManageSessionService;

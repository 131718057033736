import { MenuOption } from "src/components/HeaderBarMenu";

export const baseHeaderMenuOptions: MenuOption[] = [
  { id: "account", label: "Account" },
  { id: "support", label: "Support", disabled: true },
  { id: "sign-out", label: "Sign out" },
];

export const authenticatedHeaderMenuOptions: MenuOption[] = [
  { id: "account", label: "Account" },
  { id: "support", label: "Support" },
  { id: "sign-out", label: "Sign out" },
];

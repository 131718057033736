import styled from "@emotion/styled";
import { alpha, Typography } from "@mui/material";
import em from "src/utils/em";
import space from "src/utils/space";

export const Base = styled.a`
  position: relative;
  display: flex;
  gap: ${space(1)};
  padding: ${space(1)} ${space(2)};
  color: inherit;
  text-decoration: none;

  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover,
  &:focus {
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
  }
`;

export const BaseGroupPrimary = styled.span`
  position: relative;
  display: block;
  width: 100%;
`;

export const BaseGroupSecondary = styled.span`
  position: relative;
  display: block;
  padding: ${space(0.7)} 0;
  display: flex;
  justify-content: center;
  width: ${em(20)};
`;

export const BaseTitle = styled(Typography)``;

export const BaseTime = styled(Typography)`
  font-weight: 400;
  font-style: italic;
  font-size: ${em(12)};
`;

interface BaseNotReadProps {
  active?: boolean;
}

export const BaseNotRead = styled.span<BaseNotReadProps>`
  position: relative;
  display: block;
  width: ${em(6)};
  opacity: ${({ active }) => (active ? 1 : 0.0)};
  height: ${em(6)};
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

BaseNotRead.defaultProps = {
  active: false,
};

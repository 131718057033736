import { NavigationContext, NavigationEvent } from "..";

// TODO - Move into config
const primaryList = [
  "/projects/create-project",
  "/projects/create-project/team",
  "/projects/create-project/logistics",
  "/projects/create-project/complete",
  "/projects",
  "/casting-briefs",
  "/calendar",
  "/directory",
  "/directory#artist",
  "/directory#agencies",
  "/directory#casting",
  "/directory/message-agents",
  "/messages",
  "/artist-roster",
  "/artist-roster/invite-your-artists",
  "/artist-roster/confirmation",
  "/artist-roster/artist-check",
  "/artist-roster/submission-report",
  "/view-profile",
  "/edit-profile",
  "/edit-profile/cover-photo",
  "/edit-profile/headshots",
  "/edit-profile/key-vitals",
  "/edit-profile/biography",
  "/edit-profile/measurements-and-attributes",
  "/edit-profile/secondary-agency-representation",
  "/edit-profile/credits",
  "/edit-profile/credits/edit-credit",
  "/edit-profile/credits/add-credit",
  "/edit-profile/credits/bulk-upload-of-credits",
  "/edit-profile/materials",
  "/edit-profile/training",
  "/edit-profile/training/edit-training",
  "/edit-profile/training/add-training",
  "/edit-profile/awards-and-nominations",
  "/edit-profile/awards-and-nominations/edit-award",
  "/edit-profile/awards-and-nominations/add-award",
  "/edit-profile/skills",
  "/edit-profile/skills/edit-skill",
  "/edit-profile/skills/add-skill",
  "/edit-profile/additional-information",
  "/edit-profile/additional-information/edit",
  "/roles",
  "/account-details",
  "/account-details#personal",
  "/account-details#company",
  "/account-details#team",
  "/account-details#templates",
  "/account-details/create-wardrobe-template",
  "/account-details#representation",
  "/account-details/edit-personal",
  "/account-details/edit-artist",
  "/account-details/edit-company",
  "/account-details/change-password",
  "/account-details/password-changed",
  "/account-details/invite-team-members",
  "/account-details/invite-team-members/confirm",
  "/account-details/subscription",
  "/admin/approvals",
  "/data",
  "/comms",
  "/comms/confirmation",
  "/reset-password/welcome-back",
  "/directory",
  "/directory/agencies",
  "/directory/casting-office",
  "/status-report",
  "/lists",
  "/lists#artists",
  "/lists#agencies",
  "/lists/artist",
  "/lists/agency",
  "/bulk-import",
  "/bulk-import/headshots",
  "/bulk-import/complete",
  "/",
];

const isPrimary = (path: string) =>
  path.startsWith("/casting-briefs") ||
  primaryList.some((v) => v === path) ||
  path.startsWith("/directory/agencies") ||
  path.startsWith("/directory/casting-office") ||
  path.startsWith("/status-report") ||
  path.startsWith("/lists/artist") ||
  path.startsWith("/lists/agency") ||
  path.startsWith("/status-report#") ||
  path.startsWith("/data")||
  path.startsWith("/account-details");

export const isPrimaryRoute = (
  ctx: NavigationContext,
  evt: NavigationEvent
) => {
  if (evt.type != "UPDATE") throw Error("Should only be used on UPDATE event");
  const path =
    evt.path.indexOf("?") === -1
      ? evt.path
      : evt.path.substring(0, evt.path.indexOf("?"));
  return isPrimary(path);
};

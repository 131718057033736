import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from "@microsoft/signalr";
import RecordingUser from "../altai/entities/sessions/RecordingUser";
import PushType from "./entities/PushType";
import SessionPush from "./entities/SessionPush";
import { WaitingRoomUserStatus } from "src/machines/runSessionMachine";
import SessionStatus from "../altai/entities/sessions/SessionStatus";

export type WebSocketEvent = {
  type: PushType;
  agoraSession: string | null;
  conferenceId: string | undefined;
  users: RecordingUser[] | null;
  waitingRoomUserStatus: WaitingRoomUserStatus[] | null;
  sessionStatus: SessionStatus | null;
  connectionId?: string;
};

export type SubscriberItem = {
  id: string;
  callback: (event: WebSocketEvent) => void;
};

class WebSocketSessionService {
  /********************************************** */
  // vars
  /********************************************** */
  wsClient: HubConnection | null = null;
  url: string | null = "";
  subscribers: SubscriberItem[] = [];

  /********************************************** */
  // Constructor
  /********************************************** */
  constructor(url: string) {
    this.url = url;
  }

  /********************************************************************** */
  // Private
  /********************************************************************** */

  /********************************************** */
  // Transform response
  /********************************************** */
  private _toPushData(data: any): SessionPush | null {
    if (data === null) return null;
    return {
      type: data?.type ?? PushType.Joined,
      agoraSession: data?.agoraSession ?? null,
      conferenceId: data?.conferenceId,
      users: data?.users ?? null,
      waitingRoomUserStatus: data?.waitingRoomUserStatus ?? null,
      sessionStatus: data?.sessionStatus ?? null,
      connectionId: data?.connectionId
    };
  }

  /********************************************** */
  // Initiate connection
  /********************************************** */

  private _init(url: string): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(url, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
      })
      .withAutomaticReconnect()
      .build();
  }

  /********************************************** */
  // Event - Initial connection
  /********************************************** */
  private _onIndividualConnectionHandler = 
    (callback: (event: WebSocketEvent) => void) => (connectionId?: string) => {
      console.log(`Sessions WebSocket Connected ${connectionId}`);
      callback({
        type: PushType.Connected,
        agoraSession: null,
        conferenceId: undefined,
        users: null,
        waitingRoomUserStatus: null,
        sessionStatus: null,
        connectionId: connectionId
      });
    };

  /********************************************** */
  // Event - Data update
  /********************************************** */
  private _onReceiveDataHandler =
    (callback: (event: WebSocketEvent) => void) => (data: any) => {
      const pushData = this._toPushData(data);
      if (pushData === null) return;
      // Notify subscribers
      callback({ ...pushData, type: pushData.type });
    };

  /********************************************** */
  // Event - Connection closed
  /********************************************** */
  private _onCloseHandler = (error: any) => {};

  private _toPath(url: string, timelineId: string, userId?: string): string {
    const res = url.replace("[timelineId]", timelineId);
    return userId? `${res}&userId=${userId}`: res;
  }

  /********************************************************************** */
  // Public
  /********************************************************************** */

  /********************************************** */
  // Initiate connection
  /********************************************** */
  public async connect(
    timelineId: string,
    callback: (evt: WebSocketEvent) => void
  ): Promise<void> {
    // Check dependencies
    if (typeof timelineId !== "string") {
      throw new Error("timelineId must be a string");
    }
    if (this.url === "") {
      throw new Error("No url provided");
    }

    // Initialise connection
    const path = this._toPath(this.url!, timelineId);
    this.wsClient = this._init(path);
    this.wsClient.on(
      "onIndividualConnection",
      this._onIndividualConnectionHandler(callback)
    );
    this.wsClient.on("receiveData", this._onReceiveDataHandler(callback));
    this.wsClient.onclose(this._onCloseHandler);

    // Initiate connection
    await this.wsClient.start();
  }

  /********************************************** */
  // Disconnect
  /********************************************** */
  public async disconnect(
    callback: (evt: WebSocketEvent) => void
  ): Promise<void> {
    if (this.wsClient === null) return;

    // Garbage collection
    this.wsClient.off(
      "onIndividualConnection",
      this._onIndividualConnectionHandler
    );
    this.wsClient.off("receiveData", this._onReceiveDataHandler(callback));

    // Close connection
    await this.wsClient.stop();
    this.wsClient = null;
  }
}

export default WebSocketSessionService;

import { NavItem } from "src/types/NavItem";
import { PathNameReplace } from "..";

const toReplacePathBreadcrumb =
  (breadcrumbPaths: PathNameReplace[]) => (v: NavItem) => {
    // If there is no breadcrumb paths, return the original
    const isMatch = breadcrumbPaths.find(
      (v2) => v2.original?.toLowerCase() === v.id.toLowerCase()
    );
    if (isMatch) {
      return { ...v, label: isMatch.replacement };
    }
    return v;
  };

export default toReplacePathBreadcrumb;

import type { AppContext } from "..";
import { initialValues as forgotPasswordInitialValues } from "src/validations/forgotPassword";
import { initialValues as resetPasswordInitialValues } from "src/validations/resetPassword";
import { string } from "yup/lib/locale";

const context: AppContext = {
  auth: {
    accessToken: null,
    userType: null,
    firebaseId: null,
    errorCode: null,
    username: null,
    isTeamAdmin: false,
  },
  passwordResetUserEmail: "",
  paymentStatus: null,
  forgotPassword: forgotPasswordInitialValues,
  resetPassword: resetPasswordInitialValues,
  messagingUserToken: null,
  agencyUserDetails: null,
  artistHomePageDetails: null,
  userDetailsLoaded: false,
  isFirstDayOnAltai: null,
  userFirstName: "",
  cdHomePageDetails: null,
  customToken: null,
  routerPath: "",
  lastName: "",
};

export default context;

import { NavItem } from "src/types/NavItem";

const agencyPrimaryRoutes: NavItem[] = [
  {
    id: "casting-briefs",
    label: "Casting Briefs",
    href: "/casting-briefs",
  },
  {
    id: "status-report",
    label: "Status Report",
    href: "/status-report",
  },
  {
    id: "calendar",
    label: "Calendar",
    href: "/calendar",
  },
  {
    id: "roster",
    label: "Artist Roster",
    href: "/artist-roster",
  },
  {
    id: "directory",
    label: "Directory",
    href: "/directory",
  },
];

export default agencyPrimaryRoutes;

import { NavItem } from "src/types/NavItem";

const adminPrimaryRoutes: NavItem[] = [
  {
    id: "approvals",
    label: "Admin Approvals",
    href: "/admin/approvals",
  },
  {
    id: "bulkImport",
    label: "Bulk Import",
    href: "/bulk-import",
  },
  {
    id: "data",
    label: "Data",
    href: "/data",
  },
  {
    id: "directory",
    label: "Directory",
    href: "/directory",
  },
  {
    id: "comms",
    label: "Comms",
    href: "/comms",
  },
];

export default adminPrimaryRoutes;

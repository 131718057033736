import { StreamItem, StreamItemDetail } from "..";

// NOTE - Requires other verbs to help define messaging
// NOTE - This is temporary messages, will need to confirm
export const toStreamItemMessage = (
  verb: StreamItem["verb"],
  detail: StreamItemDetail
): string => {
  switch (verb) {
    case "client_commented_on_artist_tape_notification":
      return `New comment received on ${detail.artist_name}'s tape for ${detail.project_name}, ${detail.folder_name} folder`;
    case "client-commented-on-artist-list-notification":
      return `New comment received for ${detail.artist_name} in the ${detail.list_name} list for ${detail.project_name}`;
    case "signed-nda-to-review-notification":
      return `${detail.project_name} has an NDA awaiting approval`;
    case "new_role_submissions_for_review_notification":
      return `You have ${detail.unviewed_submissions} ${
        detail.unviewed_submissions! > 1 ? "submissions" : "submission"
      } awaiting review for the role of ${detail.role_name} in ${
        detail.project_name
      }`;
    case "unviewed_self_tapes_notification":
      return `${detail.project_name} has ${
        detail.unviewed_self_tapes
      } unviewed self ${detail.unviewed_self_tapes! > 1 ? "tapes" : "tape"}`;
    default: {
      return "";
    }
  }
};

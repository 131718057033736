import {
  Grow,
  IconButton,
  MenuItem,
  Paper,
  ClickAwayListener,
} from "@mui/material";

import { useRef, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { Base, BaseMenu, BasePopper } from "./styles";

export interface MenuOption {
  id: string;
  label: string;
  disabled?: boolean;
}

interface HeaderBarProps {
  options?: MenuOption[];
  onChange: (option: MenuOption["id"]) => void;
}

const HeaderBarMenu = ({ options, onChange }: HeaderBarProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [active, setActive] = useState<boolean>(false);

  //
  const onSelectHandler = (id: MenuOption["id"]) => {
    onChange(id);
    setActive(false);
  };

  return (
    <>
      <Base>
        <IconButton ref={ref} onClick={() => setActive(true)}>
          <AccountCircleIcon />
        </IconButton>
      </Base>
      <BasePopper
        open={active}
        anchorEl={ref.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "top right",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setActive(false)}>
                  <BaseMenu>
                    {options!.map((option) => (
                      <MenuItem
                        disabled={option.disabled}
                        key={option.id}
                        onClick={() => onSelectHandler(option.id)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </BaseMenu>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </BasePopper>
    </>
  );
};

HeaderBarMenu.defaultProps = {
  options: [
    { id: "account", label: "Account" },
    { id: "my-profile", label: "My profile", disabled: true },
    { id: "support", label: "Support", disabled: true },
    { id: "help", label: "Help", disabled: true },
    { id: "profile", label: "Artist profile (preview)" },
    { id: "sign-out", label: "Sign out" },
  ] as MenuOption[],
};

export default HeaderBarMenu;

import { StreamApp } from "react-activity-feed";

import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useApp } from "src/contexts/AppContext";
import { useActor, useMachine } from "@xstate/react";
import notificationMachine from "src/machines/notificationMachine";
import NotificationStreamItem from "src/components/NotificationStreamItem";

import "react-activity-feed/dist/index.css";

import {
  Base,
  BaseDropdown,
  BaseHeaderLink,
  BaseNotifier,
  BasePlaceHolder,
  BasePrimaryTitle,
} from "./styles";

import toStreamItem from "../NotificationStreamItem/utils/toStreamItem";
import { useRef, useState } from "react";
import SavingSuccess from "../SavingSuccess";

NotificationStreamItem;

const NotificationStream = () => {
  const { appService } = useApp();
  const [appState] = useActor(appService);

  const [state, send] = useMachine(notificationMachine, {
    devTools: true,
    context: {
      userType: appState.context?.auth?.userType ?? null,
    },
    actions: {
      notifyMarkAllReadComplete: () => {
        setShowMarkAllCompleted(true);
        setTimeout(() => {
          setShowMarkAllCompleted(false);
        }, 500);
      },
    },
  });

  const { messagingUserToken } = appState.context;
  const markAllRead = () => {
    send("MARK_ALL_AS_READ");
  };

  const [showMarkAllCompleted, setShowMarkAllCompleted] = useState(false);

  const isSavingMarkAll = state.matches("markAllMessageAsRead");

  const [readAndSeenOptions, setReadAndSeenOptions] = useState({
    read: false,
    seen: false,
  });
  const [showMarlAllAsReadLink, setShowMarlAllAsReadLink] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <Base>
        {messagingUserToken !== null && (
          <StreamApp
            apiKey={process.env.NEXT_PUBLIC_GETSTREAM_KEY!}
            appId={process.env.NEXT_PUBLIC_GETSTREAM_APP_ID!}
            token={messagingUserToken!}
          >
            <BaseDropdown
              notify
              right
              Icon={() => (
                <NotificationsNoneOutlinedIcon
                  onClick={() =>
                    setReadAndSeenOptions({ read: true, seen: true })
                  }
                  style={{ marginTop: "0.25em", fill: "currentColor" }}
                />
              )}
              width={330}
              Header={
                showMarlAllAsReadLink
                  ? () => (
                      <BaseHeaderLink
                        // @ts-ignore
                        component="button"
                        onClick={() => markAllRead()}
                      >
                        <SavingSuccess
                          topOffset={10}
                          saving={isSavingMarkAll}
                          savingSuccess={showMarkAllCompleted}
                        />
                        <BasePrimaryTitle variant="h6">
                          Mark all as read
                        </BasePrimaryTitle>
                      </BaseHeaderLink>
                    )
                  : null
              }
              Notifier={BaseNotifier}
              Placeholder={() => (
                <BasePlaceHolder>
                  <BasePrimaryTitle variant="body1">
                    You have no new notifications
                  </BasePrimaryTitle>
                </BasePlaceHolder>
              )}
              Group={(group) => {
                const item = toStreamItem(group.activityGroup);
                if (item === null) {
                  return null;
                } else {
                  setShowMarlAllAsReadLink(true);
                }
                return <NotificationStreamItem {...item} />;
              }}
              options={{
                mark_read: readAndSeenOptions.read,
                mark_seen: readAndSeenOptions.seen,
              }}
            />
          </StreamApp>
        )}
      </Base>
    </>
  );
};

export default NotificationStream;

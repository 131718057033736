import * as yup from "yup";

export interface ResetPasswordProps {
  resetToken: string;
  newPassword: string;
  confirmPassword: string;
}

export const initialValues: ResetPasswordProps = {
  resetToken: "",
  newPassword: "",
  confirmPassword: "",
};

const schema = yup.object({
  newPassword: yup
    .string()
    .min(8, "Password length must be 8 characters or more")
    .required("You must enter a password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,99}$/,
      "Password does not meet requirements"
    ),
  confirmPassword: yup
    .string()
    .required("You must confirm your password")
    .oneOf([yup.ref("newPassword"), null], "Must match your password"),
});

export default schema;

import { useRouter } from "next/router";
import { ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Brand from "src/components/Brand";

import {
  Base,
  BaseLeading,
  BaseBody,
  BaseActions,
  BaseMenu,
  BaseGroup,
} from "./styles";

interface HeaderBarProps {
  breadcrumb?: ReactNode;
  breadcrumbActive?: boolean;
  actions?: ReactNode;
  onOpen: () => void;
  disableHamburger?: boolean;
}

const HeaderBar = ({
  onOpen,
  breadcrumb,
  actions,
  breadcrumbActive,
  disableHamburger,
}: HeaderBarProps) => {
  const router = useRouter();

  return (
    <Base>
      <BaseGroup>
        <BaseLeading disableHamburger={disableHamburger}>
          {!disableHamburger && (
            <BaseMenu>
              <IconButton onClick={() => onOpen()} color="primary">
                <MenuIcon fontSize="medium" />
              </IconButton>
            </BaseMenu>
          )}
          <Brand onClick={() => router.push("/")} />
        </BaseLeading>
        <BaseBody active={breadcrumbActive}>{breadcrumb}</BaseBody>
      </BaseGroup>
      <BaseActions>{actions}</BaseActions>
    </Base>
  );
};

HeaderBar.defaultProps = {
  breadcrumbActive: true,
};

export default HeaderBar;

import { NavItem } from "src/types/NavItem";

const basePrimaryRoutes: NavItem[] = [
  {
    id: "projects",
    label: "Projects",
    href: "/projects",
  },
  {
    id: "calendar",
    label: "Calendar",
    href: "/calendar",
  },
  {
    id: "directory",
    label: "Directory",
    href: "/directory",
  },
  {
    id: "lists",
    label: "Lists",
    href: "/lists",
  },
];

export default basePrimaryRoutes;

declare enum PushType {
  Started = 0,
  Ended = 1,
  Joined = 2,
  Left = 3,
  RecordingStarting = 6,
  RecordingStarted = 4,
  RecordingStopping = 7,
  RecordingStopped = 5,
  UserPinned = 8,
  UserMuted = 9,
  WaitingRoomJoined = 10,
  Admit = 11,
  Remove = 12,
  Disconnect = 13,
  Connected = 14,
  ConnectionLost = 15,
  QualityReport = 16,
}

export default PushType;

import { NavItem } from "src/types/NavItem";

const artistPrimaryRoutes: NavItem[] = [
  {
    id: "view-profile",
    label: "View Profile",
    href: "/view-profile",
  },
  {
    id: "edit-profile",
    label: "Edit Profile",
    href: "/edit-profile",
  },
  {
    id: "status-report",
    label: "Status Report",
    href: "/status-report",
  },
  {
    id: "calendar",
    label: "Calendar",
    href: "/calendar",
  },
];

export default artistPrimaryRoutes;
